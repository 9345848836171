<template>
  <el-dialog :title="formType == 'add' ? '新增' : '编辑'" width="500px" :visible="show" @close="close" :close-on-click-modal="false" append-to-body>
    <el-form ref="form" :model="form" label-width="70px" :rules="rules">
      <el-form-item label="类型" prop="type">
        <el-radio-group v-model="form.type" :disabled="formType != 'add'">
          <el-radio :label="1">目录</el-radio>
          <el-radio :label="2">文档</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="名称" prop="documentName">
        <el-input v-model="form.documentName" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="项目类型" prop="rangeFlagEnum">
        <el-radio-group v-model="form.rangeFlagEnum" @input="changeType">
          <el-radio label="global">全局</el-radio>
          <el-radio label="app">应用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="项目编码" prop="code">
        <el-select v-if="form.rangeFlagEnum == 'global'" v-model="form.code" style="width: 100%">
          <el-option v-for="(item, index) in globalAppList" :key="index" :label="item.name" :value="item.code"></el-option>
        </el-select>
        <el-select v-if="form.rangeFlagEnum == 'app'" v-model="form.code" style="width: 100%">
          <el-option v-for="(item, index) in otherAppList" :key="index" :label="item.name" :value="item.code"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否开放" prop="isOpen">
        <el-switch v-model="form.isOpen" :active-value="1" :inactive-value="0"> </el-switch>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.remark" type="textarea" placeholder="请输入"> </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="onSubmit">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import api from '@/api/operate/fileMgt'
export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    formType: {
      default: () => 'add',
      type: String,
    },
    pitem: {
      default: () => null,
      type: Object,
    },
    detail: {
      default: () => {},
      type: Object,
    },
  },
  watch: {
    show(val) {
      if (val) {
        if (this.formType == 'add') {
          this.form = {
            type: 1,
            isOpen: 1,
            rangeFlagEnum: this.pitem.rangeFlagEnum || 'global',
            code: this.pitem.code || '',
          }
        } else if (this.formType == 'edit') {
          this.form = {
            ...this.detail,
          }
        }
      } else {
        this.reset()
      }
    },
  },
  mounted() {
    this.getAppCodeList()
  },
  data() {
    return {
      form: {
        type: 1,
      },
      rules: {
        type: [{ required: true, message: '请选择类型', trigger: 'blur' }],
        documentName: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        isOpen: [{ required: true, message: '请选择', trigger: 'blur' }],
        code: [{ required: true, message: '请选择项目编码', trigger: 'blur' }],
        rangeFlagEnum: [{ required: true, message: '请选择项目类型', trigger: 'blur' }],
      },
      globalAppList: [],
      otherAppList: [],
    }
  },
  methods: {
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          const params = {
            ...this.form,
            parentId: this.formType == 'add' ? this.pitem.id : this.form.parentId,
          }
          const fn = this.formType == 'add' ? api.addDirectory : api.updateDirectory
          fn(params).then(() => {
            this.$message.success('操作成功')
            this.close()
            this.$emit('success')
          })
        }
      })
    },
    reset() {
      this.$refs['form']?.resetFields()
    },
    close() {
      this.$emit('update:show', false)
    },
    getAppCodeList() {
      api.getGlobalApp().then(({ data }) => {
        console.log({ data })
        this.globalAppList = data.baseDataTree
      })
      api.getOtherApp().then(({ data }) => {
        console.log({ data })
        this.otherAppList = data.baseDataTree
      })
    },
    changeType() {
      this.$set(this.form, 'code', '')
    },
  },
}
</script>
