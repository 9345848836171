import http from '@/utils/axios'

const data = {
  getSign: (data) => http.get('eds/oss/signature', data),
  ossUpload: (url, data, config) => http.post(url, data, config),
  getSignatureUrl: (data) => http.get(`eds/oss/signatureUrl/${data}`),
  //
  getDirectoryTree: (data) => http.get(`eds/directory/get`, data),
  addDirectory: (data) => http.post('eds/directory/add', data),
  updateDirectory: (data) => http.put('eds/directory/update', data),
  deleteDirectory: (data) => http.delete(`eds/directory/delete/${data}`),
  getDirectoryDetail: (data) => http.get(`eds/directory/get/document/content/${data}`),
  updateDirectoryDetail: (data) => http.put(`eds/directory/update/document/content`, data),
  getDocumentName: (data) => http.get(`eds//directory/getDocumentName/${data}`),
  getGlobalApp: (data) => http.get(`tenant-user/baseData/front_desk/global/query`, data),
  getOtherApp: (data) => http.get(`tenant-user/baseData/front_desk/app/query`, data),
  moveDirectory: (data) => http.put('eds/directory/move', data),
}

export default data
