<template>
  <div class="h100">
    <el-row class="h100" :gutter="5">
      <el-col :span="5">
        <el-form>
          <el-form-item>
            <el-input v-model="documentName" placeholder="请输入文档名称" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getDirectoryTree">查询</el-button>
            <el-button type="primary" plain @click="handleAdd({ id: 0 })">新增</el-button>
          </el-form-item>
        </el-form>
        <el-tree draggable @node-drop="handleDrop" node-key="id" :data="tree" :props="defaultProps" :defaultProps="defaultProps" default-expand-all highlight-current @node-click="handleNodeClick">
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span class="mr10">
              <i v-if="data.type == 1" class="el-icon-menu" />
              <i v-if="data.type == 2" class="el-icon-files" />
              <span v-html="data.documentName"></span>
            </span>
            <span v-show="curTreeNode && curTreeNode.id == data.id">
              <el-button type="text" icon="el-icon-plus" title="新增" v-if="data.type == 1" @click.stop="() => handleAdd(data)"> </el-button>
              <el-button type="text" icon="el-icon-edit" title="编辑" @click.stop="() => handleEdit(data)"> </el-button>
              <el-button type="text" icon="el-icon-remove-outline" v-if="data.isOpen == 1" title="禁用" @click.stop="() => handleUpdateStatus(data)"> </el-button>
              <el-button type="text" icon="el-icon-circle-check" v-else title="启用" @click.stop="() => handleUpdateStatus(data)"> </el-button>
              <el-button type="text" icon="el-icon-delete" title="删除" @click.stop="() => handleDelete(data)"> </el-button>
            </span>
          </span>
        </el-tree>
      </el-col>
      <el-col :span="19" class="h100">
        <template v-if="docDetail">
          <common-layout>
            <template #header>
              <div class="primary-tip flex-bc mb10">
                <div>
                  <div>{{ docDetail.documentName }}</div>
                  <div class="font12 mt5" style="color: #999; font-style: italic">
                    <span class="mr5">创建人：{{ docDetail.creator }}</span>
                    <span class="mr5">创建时间：{{ docDetail.createTime }}</span>
                    <span class="mr5">更新人：{{ docDetail.updater }}</span>
                    <span class="mr5">更新时间：{{ docDetail.updateTime }}</span>
                  </div>
                </div>
                <el-button type="primary" @click="handleEditContent">保存</el-button>
              </div>
            </template>
            <template>
              <mavonEditor ref="md" v-model="docDetail.content" @imgAdd="handleImgAdd" style="height: 100%" />
            </template>
          </common-layout>
        </template>
      </el-col>
    </el-row>
    <addModal :show.sync="showAdd" :formType="formType" :pitem="pitem" :detail="curItem" @success="getDirectoryTree" />
  </div>
</template>
<script>
import api from '@/api/operate/fileMgt'
import addModal from './addModal'
import { mavonEditor } from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
import commonApi from '@/api/common'
export default {
  components: { addModal, mavonEditor },
  data() {
    return {
      tree: [],
      showAdd: false,
      formType: 'add',
      pitem: null,
      defaultProps: {
        children: 'chirldren',
        label: 'documentName',
      },
      curItem: null,
      docDetail: null,
      documentName: '',
      curTreeNode: null,
    }
  },
  mounted() {
    this.getDirectoryTree()
  },
  methods: {
    getDirectoryTree() {
      const fn = this.documentName ? api.getDocumentName : api.getDirectoryTree
      fn(this.documentName).then(({ data }) => {
        console.log({ data })
        this.tree = data
      })
    },
    handleAdd(item) {
      this.pitem = { ...item }
      this.formType = 'add'
      this.showAdd = true
    },
    handleEdit(data) {
      this.curItem = { ...data }
      this.formType = 'edit'
      this.showAdd = true
    },
    handleDelete(data) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        api.deleteDirectory(data.id).then(() => {
          this.$message.success('操作成功')
          this.getDirectoryTree()
        })
      })
    },
    handleDetail(id) {
      api.getDirectoryDetail(id).then(({ data }) => {
        console.log({ data })
        this.docDetail = null
        this.$nextTick(() => {
          this.docDetail = data
        })
      })
    },
    changeEditor(val) {
      this.docDetail.content = val
    },
    handleEditContent() {
      console.log(this.docDetail.content)
      const params = {
        ...this.docDetail,
      }
      api.updateDirectoryDetail(params).then(() => {
        this.$message.success('操作成功')
      })
    },
    handleNodeClick(data) {
      console.log({ data })
      this.curTreeNode = { ...data }
      if (data.type == 2) {
        this.handleDetail(data.docId)
      }
    },
    handleImgAdd(val, file) {
      const params = new FormData()
      params.append('file', file)
      params.append('businessType', 'system_icon')
      commonApi.fileUpload(params).then(({ data }) => {
        let url = data.ossUrl
        this.$refs['md'].$img2Url(val, url)
      })
    },
    handleUpdateStatus(data) {
      this.$confirm(`${data.isOpen == 1 ? '确定禁用吗' : '确定启用吗'}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        const params = {
          ...data,
          isOpen: data.isOpen == 1 ? 0 : 1,
        }
        api.updateDirectory(params).then(() => {
          this.$message.success('操作成功')
          this.getDirectoryTree()
        })
      })
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
      console.log(draggingNode, dropNode, dropType)
      const params = {
        dragId: draggingNode.data.id,
        dropId: dropNode.data.id,
        dropType,
      }
      api.moveDirectory(params).then(() => {
        this.$message.success('操作成功')
        this.getDirectoryTree()
      })
    },
  },
}
</script>
